import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { valuesHaveContent, mailLooksValid } from '../../../helpers/validation.helper';
import { selectCurrency, selectLanguage } from '../../../store/appInfoSlicer';
import { initializeTranslator } from '../../../helpers/language.helper';
import { fetchGatewayValues } from '../../../helpers/payment-gateway.helper';
import {companies, paymentMethods} from '../../../store/enums';
import { getPaymentMethodForCards, submitRegistrationRequest } from './helpers';
import { initializeNiubizPayform, tokenizeCardAndFinishNiubizTransaction } from './niubizHelper';
import { initializeDlocalForm, tokenizeCardAndFinishDlocalTransaction } from './dlocalHelper';
import { facebookPixelEvents, triggerFacebookPixelEvent } from '../../../helpers/facebook-pixel.helper';
import { useParams } from "react-router-dom";
import { calculateCoursePrices } from "../../../helpers/courses.helper";
import RoundButton from '../../core/RoundButton/RoundButton';
import telephoneCodes from '../../../assets/telephone-codes';
import Stepper from '../Stepper/Stepper';
import './PaymentWithCard.scss';

// Tabs 'enum'
const Tabs = Object.freeze({
  personalData: 'personalData',
  cardData: 'cardData'
});

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentWithCard = (props) => {

  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // get payment according current currency
  let paymentMethod = getPaymentMethodForCards(currency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  // tokens for payment gateway
  const [gatewayToken, setGatewayToken] = useState({});
  // validation flag
  const [requiredValuesAreFilled, setRequiredValuesAreFilled] = useState(false);
  // card holder: first name
  const [firstName, setFirstName] = useState('');
  // card holder: last name
  const [lastName, setLastName] = useState('');
  // card holder: mobile and country code
  const [telephoneCountriesMenuIsVisible, setTelephoneCountriesMenuIsVisible] = useState(false);
  const [filteredTelephoneCountries, setFilteredTelephoneCountries] = useState([]);
  const [telephoneCountry, setTelephoneCountry] = useState('peru');
  const [mobile, setMobile] = useState('');
  const [dni, setDni] = useState("");

  // CURP: mexican identity document
  const [curp, setCurp] = useState('');
  const [curpIsVisible, setCurpIsVisible] = useState(false);
  // card holder: email
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // payment installments count
  const [installments, setInstallments] = useState(1);
  // payment installments: is visible or not
  const [installmentsIsVisible, setInstallmentsIsVisible] = useState(false);
  // terms and conditions has been accepted
  const [termsAccepted, setTermsAccepted] = useState(false);
  // card errors
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCvcError, setCardCvcError] = useState('');
  const [cardIsValid, setCardIsValid] = useState(false);
  // active tab
  const [activeTab, setActiveTab] = useState(Tabs.personalData);
  // step number
  const [stepNumber, setStepNumber] = useState(1);

  // ---------------------------------------
  // Functions

  // switch tab to card form
  const showCardTab = async () => {

    // show spinner before request
    props.showSpinner();

    // fetch payment gateway tokens
    let gatewayToken = await fetchGatewayValues(
        getPaymentMethodForCards(currency),
        currency,
        language,
        props.company,
        props.courseCode,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          mobile: telephoneCodes[telephoneCountry] + ' ' + mobile,
          curp: curp,
          dni: dni
        },
        () => {
          props.hideSpinner();
          props.showNotification('')
        },
        props.withInstallments,
        params.installmentNumber,
        props.certificates,
        params.studentId
    );

    // change active tab
    setActiveTab(Tabs.cardData);

    // Lock price to avoid to be changed
    props.onLockPrice();

    // execute callback to generate card form
    if (gatewayToken) generateCardForm(gatewayToken);

    // tell parent component to recalculate payment
    // information wrapper height
    props.onFormSizeChange();

    // update step
    setStepNumber(2);

    // trigger facebook pixel event
    triggerFacebookPixelEvent(
        facebookPixelEvents.botonSiguienteTarjeta,
        props.courseName,
        props.company
    );
  }

  // generate credit/debit card from
  const generateCardForm = (gatewayToken) => {

    let prices = calculateCoursePrices(
        props.course, props.withInstallments, props.certificates
    );

    // update state
    setGatewayToken(gatewayToken);

    // initialize Niubiz form
    if (paymentMethod === paymentMethods.niubiz) {

      initializeNiubizPayform(
          gatewayToken,
          prices.totalPrice,
          () => props.hideSpinner(),
          (message) => setCardNumberError(message) ,
          (message) => setCardExpiryError(message),
          (message) => setCardCvcError(message),
          (binType) => {
            // installments are visible only with Credit Cards
            let isVisible = (binType === 'C');
            setInstallmentsIsVisible(isVisible);
          },
          language
      );
    }

    // intialize dLocal form
    if (paymentMethod === paymentMethods.dlocal) {

      initializeDlocalForm(gatewayToken,
          () => props.hideSpinner(),
          (message) => setCardNumberError(message),
          (message) => setCardExpiryError(message),
          (message) => setCardCvcError(message),
          {
            placeholder: t('typeHere')
          });
    }
  }

  /**
   * Validate form with personal data
   */
  const validatePersonalForm = () => {

    let requiredFields = paymentMethod === paymentMethods.niubiz
      ? [ firstName, lastName, email, dni ]
      : [ firstName, lastName, email ]

    // check whether required fields have content
    let requiredValid = valuesHaveContent(requiredFields);

    // validation: mobile is valid when it has at least 9 characters
    let mobileIsValid = mobile.length >= 9;

    // email address looks valid
    let emailLooksValid = mailLooksValid(email);
    if (email) {
      if (emailLooksValid) {
        setEmailError('');
      } else {
        setEmailError(t('emailIsInvalid'));
      }
    }

    // when payment method is dLocal also validate identity document
    if (paymentMethod === paymentMethods.dlocal) {
      let curpIsValid = (curp.length >= 10) && (curp.length <= 18);
      setRequiredValuesAreFilled(
          requiredValid && emailLooksValid && mobileIsValid && curpIsValid
      );
    }

    // when payment method is Niubiz, fist name, last name, email
    // and mobile are requiered
    if (paymentMethod === paymentMethods.niubiz) {
      setRequiredValuesAreFilled(
          requiredValid && emailLooksValid && mobileIsValid
      );
    }
  }

  /**
   * Validate form values
   */
  const validateCardForm = () => {

    // card values are valid
    let cardIsValid = (!cardNumberError && !cardExpiryError && !cardCvcError);

    // update validation flag
    setCardIsValid(cardIsValid && termsAccepted);
  }

  /**
   * Callback to be executed after payment transaction was successful
   */
  const successTransactionCallback = (
      purchaseNumber, amount, currency, brand, card, transactionDate
  ) => {
    
    props.hideSpinner();

    // show transaction result page
    
    props.onTransactionFinished(purchaseNumber, amount, currency, brand, card, transactionDate);

    // register student
    
    submitRegistrationRequest(
      purchaseNumber,
      amount,
      paymentMethod,
      (message) => props.showNotification(message)
    );

    // trigger facebook pixel event

    triggerFacebookPixelEvent(
        facebookPixelEvents.purchase,
        props.courseName,
        props.company
    );
  };

  /**
   * Perform payment transaction with payment gateway
   */
  const performPaymentTransaction = () => {

    let prices = calculateCoursePrices(
        props.course, props.withInstallments, props.certificates
    );

    // show spinner
    props.showSpinner();

    if (paymentMethod === paymentMethods.niubiz) {

      // tokenize card and finish transaction with Niubiz webservice
      tokenizeCardAndFinishNiubizTransaction(
          props.company,
          gatewayToken.niubizMerchantId,
          gatewayToken.purchaseNumber,
          prices.totalPrice,
          props.courseCode,
          firstName,
          lastName,
          email,
          +installments,
          currency,
          successTransactionCallback,
          (error) => {
            props.hideSpinner();
            props.showNotification(error);
          }
      );
    }

    if (paymentMethod === paymentMethods.dlocal) {

      // tokenize card and finish transaction with dLocal webservice
      tokenizeCardAndFinishDlocalTransaction(
          props.company,
          gatewayToken.purchaseNumber,
          prices.totalPrice,
          props.courseCode,
          firstName,
          lastName,
          email,
          +installments,
          currency,
          successTransactionCallback,
          (error) => {
            props.hideSpinner();
            props.showNotification(error);
          }
      );
    }

    // trigger facebook pixel event
    triggerFacebookPixelEvent(
        facebookPixelEvents.botonPagarTarjeta,
        props.courseName,
        props.company
    );
  }

  /**
   * Update component state
   */
  const updateTelephoneCountry = (country) => {
    setTelephoneCountry(country);
    setTelephoneCountriesMenuIsVisible(false);
  }

  /**
   * Filter telephone countries according criteria
   */
  const filterTelephoneCountries = (criteria) => {

    let result = [];
    if (criteria) {

      Object.keys(telephoneCodes).map(country => {
        let matches = country.indexOf(criteria.toLowerCase()) >= 0;
        if (matches) result.push(country);
      });

    } else {
      result = Object.keys(telephoneCodes);
    }

    setFilteredTelephoneCountries(result);
  }

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {

    setFilteredTelephoneCountries(
        params.company === companies.ipappg
         ? ['peru']
         : Object.keys(telephoneCodes)
    );

    // show CURP input when payment method is dLocal
    if (paymentMethod === paymentMethods.dlocal) {
      setCurpIsVisible(true);
      setTelephoneCountry('mexico');
    }
  }, [params]);

  // execute when component is UPDATED
  useEffect(() => {
    validatePersonalForm();
    validateCardForm();
  });

  // return component
  return (<div className="payment-with-card-component">

    {/* Steps indicator */}
    <div className="pt-5 pb-5">
      <Stepper
          step={stepNumber}
          steps={[t('personalData'), t('paymentData')]}
          stepSuffix={(params.company === companies.pragmma) ? '_purple' : ''}
      />
    </div>

    {/* Cards banner */}
    {activeTab === Tabs.cardData
        ? <div className="row">
          <div className="col pb-5">
            <img src="imgs/cards-banner.png" alt=""/>
          </div>
        </div>
        : null
    }

    {/* Card details form */}
    <form className="payment-form row ps-2 pe-3 ps-lg-0 pe-lg-0">
      {/* Personal data form */}
      {activeTab === Tabs.personalData
          ? <div className="col-12 col-lg-6">
            <label>{t('cardHolderFirstName')}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input type="text"
                     name="first-name"
                     value={firstName}
                     onChange={e => setFirstName(e.target.value)}
                     placeholder={t('typeHere')} />
            </div>
          </div>
          : null
      }
      {activeTab === Tabs.personalData
          ? <div className="col-12 col-lg-6">
            <label>{t('cardHolderLastName')}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input type="text"
                     name="last-name"
                     value={lastName}
                     onChange={e => setLastName(e.target.value)}
                     placeholder={t('typeHere')} />
            </div>
          </div>
          : null
      }
      {activeTab === Tabs.personalData
          ? <div className="col-12 col-lg-6 position-relative">
            <label>{t('email')}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input type="text"
                     name="email"
                     value={email}
                     onChange={e => setEmail(e.target.value)}
                     placeholder={t('typeHere')} />
            </div>
            <span className="email error">{emailError}</span>
          </div>
          : null
      }
      {activeTab === Tabs.personalData
          ? <div className="col-12 col-lg-6 position-relative">
            <label>{
              t('mobile')}</label>
            <div className="input-wrapper position-relative mb-4 mt-2">

              <div className="dropdown telephone-codes-wrapper d-inline-block">
                {/* show/hide codes menu */}
                <button
                    onClick={() => setTelephoneCountriesMenuIsVisible(!telephoneCountriesMenuIsVisible)}
                    className="btn btn-info dropdown-toggle"
                    type="button">
                  <img src={`imgs/flags/${telephoneCountry}.svg`} alt=""/>
                  {telephoneCountriesMenuIsVisible
                      ? <img className="arrow up" src="imgs/icons/arrow-up.png" alt=""/>
                      : <img className="arrow down" src="imgs/icons/arrow-down.png" alt=""/>
                  }
                </button>
                <div className={telephoneCountriesMenuIsVisible ? 'dropdown-menu show' : 'dropdown-menu'}>
                  <div className="filter-wrapper">
                    <input type="text"
                           onChange={e => filterTelephoneCountries(e.target.value)}
                           className="form-control"
                           placeholder="Buscar" />
                  </div>
                  {   /* enumerate flags of each country */
                    filteredTelephoneCountries.map(country => {
                      return <button
                          type="button"
                          key={country}
                          className="dropdown-item"
                          onClick={() => updateTelephoneCountry(country)}>
                        <img src={`imgs/flags/${country}.svg`} alt=""/>
                        {country === 'espana' ? 'ESPAÑA'
                            : country.replaceAll('-', ' ').toUpperCase()}
                      </button>
                    })
                  }
                </div>
              </div>
              <input type="text"
                     className="d-inline-block ms-2"
                     name="mobile"
                     value={mobile}
                     onChange={e => setMobile(e.target.value)}
                     placeholder={t('typeHere')}/>
            </div>
          </div>
          : null
      }

      <div className="col-12 col-lg-6 position-relative">
        <label>DNI</label>
        <div className="input-wrapper mb-4 mt-2">
          <input
            type="text"
            name="dni"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            placeholder={t("typeHere")}
          />
        </div>
      </div>

      {activeTab === Tabs.personalData && curpIsVisible
          ? <div className="col-12 col-lg-6 position-relative">
            <label>CURP</label>
            <div className="input-wrapper mb-4 mt-2">
              <input type="text"
                     name="curp"
                     value={curp}
                     onChange={e => setCurp(e.target.value)}
                     placeholder={t('typeHere')} />
            </div>
          </div>
          : null
      }

      {/* Card values form */}
      {activeTab === Tabs.cardData
          ? <div className="col-12 col-lg-6 position-relative">
            <label>{t('cardNumber')}</label>
            <div id="card-number-wrapper"
                 className={`${cardNumberError ? 'error' : ' '} input-wrapper mb-4 mt-2`}/>
            <span className="card error">{cardNumberError}</span>
          </div>
          : null
      }
      {activeTab === Tabs.cardData
          ? <div className="col-12 col-lg-6 position-relative">
            <label>{t('expirationDate')}</label>
            <div id="card-expiry-wrapper"
                 className={`${cardExpiryError ? 'error' : ' '} input-wrapper mb-4 mt-2`}/>
            <span className="card error">{cardExpiryError}</span>
          </div>
          : null
      }
      {activeTab === Tabs.cardData
          ? <div className="col-12 col-lg-6 position-relative">
            <label>CVC / CVV</label>
            <div id="cvc-wrapper"
                 className={`${cardCvcError ? 'error' : ' '} input-wrapper mb-4 mt-2`}/>
            <span className="card error">{cardCvcError}</span>
          </div>
          : null
      }
      {installmentsIsVisible
          ? <div className="col-12 col-lg-6">
            <label>{t('installments')}</label>
            <div className="input-wrapper mb-4 mt-2">
              <select name="installments"
                      className="form-select custom-select"
                      value={installments}
                      onChange={e => setInstallments(e.target.value)}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
          </div>
          : null
      }

      <div className="col-12 pt-3">
        {activeTab === Tabs.cardData
            ? <div className="d-flex mb-5">
              <input value={termsAccepted}
                     className="form-check-input"
                     onChange={e => {
                       setTermsAccepted(e.target.checked)
                     }}
                     type="checkbox"/>
              <span className="align-self-center ms-3">
                            { t('acceptThe') } <span className="terms-link"
                                                     onClick={props.showTerms}>
                                                        { t('termsConditions').toLowerCase() }
                                                    </span>
                        </span>
            </div>
            : null
        }
      </div>

      <div className="col-12 pb-5">

        {activeTab === Tabs.personalData
            ? <RoundButton isDisabled={!requiredValuesAreFilled}
                           width={'20.625rem'}
                           onClick={showCardTab}
                           content={t('next')}/>
            : null
        }

        {activeTab === Tabs.cardData
            ? <RoundButton isDisabled={!cardIsValid}
                           width={'20.625rem'}
                           onClick={performPaymentTransaction}
                           content={t('pay')}/>
            : null
        }
        {activeTab === Tabs.cardData
            ? <div className='pt-4'>
              <RoundButton onClick={() => window.location.reload(true) }
                           width={'20.625rem'}
                           outline={true}
                           content={t('back')}/>
            </div>
            : null
        }
      </div>
    </form>

    {/* Secure payment notice */}
    {activeTab === Tabs.cardData
        ? <div className="row mt-5 mb-5">
          <div className="col secure-payment-notice d-flex">
            <div className="shield d-flex justify-content-center">
              <img src="imgs/icons/shield.svg" alt=""/>
            </div>
            <span className="ms-3 mt-2">
                        {t('oneHundredSecurePayment')}
                    </span>
          </div>
        </div>
        : null
    }
  </div>);
}

export default PaymentWithCard;

