import {initializeTranslator} from "../../../helpers/language.helper";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getInstallmentNumber} from "../../../helpers/string.helper";
import {
  changeCurrency,
  changeUserHasSetCurrency,
  selectCurrency,
  selectLanguage,
} from "../../../store/appInfoSlicer";
import {companies, countries, currencies} from "../../../store/enums";
import {
  calculateCoursePrices,
  sumCertificatesPrices,
} from "../../../helpers/courses.helper";
import TotalPrice from "../TotalPrice/TotalPrice";
import ModalDialog from "../../core/ModalDialog/ModalDialog";
import CertificateSelector from "./CertificateSelector/CertificateSelector";
import "./Items.scss";

/**
 * Capitalize first letter of the course type
 *
 * @param type
 * @returns {string|string}
 */
const formatCourseType = (type) => {

  return type ? type.charAt(0).toUpperCase() + type.slice(1)
              : '';
}

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Items = (props) => {

  const dispatch = useDispatch();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // get route parameters
  const params = useParams();

  // ---------------------------------------
  // set state values

  const [symbol, setSymbol] = useState("S/");
  const [coursePrice, setCoursePrice] = useState(0);
  const [certificatesPrice, setCertificatesPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currencyFlag, setCurrencyFlag] = useState("peru");
  const [currencyDescription, setCurrencyDescription] = useState("S/ ( PEN )");
  const [certificateDialogIsVisible, setCertificateDialogIsVisible] = useState(false);
  const [installmentsIsActive, setInstallmentsIsActive] = useState(false);
  const [chosenCertificates, setChosenCertificates] = useState([]);
  const [isPremium, setIsPremium] = useState(false);


  // ---------------------------------------
  // lifecycle hooks

  // execute when component has been MOUNTED
  useEffect(() => {
    // set symbol, flag and description
    updateCurrencyValues(currency);
  }, []);

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component has been UPDATED
  useEffect(() => {
    // update component state
    if (props.course.price) {
      setCoursePrice(props.course.price);
      setTotalPrice(props.course.price);
    }

    // update component state
    let premiumCodes = [133, 144, 143];
    setIsPremium(premiumCodes.includes(+props.course.code));
  }, [props.course]);

  // execute when component has been UPDATED
  useEffect(() => {

    if (props.course.certificates && chosenCertificates) {
      calculatePrices(installmentsIsActive);
    }

  }, [props.course, chosenCertificates]);

  // execute when component has been UPDATED
  useEffect(() => {

    props.onUpdateInstallmentsAndCertificates(
        chosenCertificates, installmentsIsActive
    );

  }, [chosenCertificates, installmentsIsActive]);

  // ---------------------------------------
  // Functions

  // convert currency
  const convertCurrency = (newCurrency) => {

    if (!newCurrency) return;

    // update values in Redux state

    dispatch(changeCurrency(newCurrency));
    dispatch(changeUserHasSetCurrency(true));

    // reload page

    window.location.reload();
  };

  /**
   * Update currency values, according provided currency
   */
  const updateCurrencyValues = (_currency) => {

    // set symbol, flag and description
    switch (_currency) {
      case currencies.pen:
        setSymbol('S/');
        setCurrencyDescription('S/ ( PEN )');
        setCurrencyFlag(countries.peru);
        break;
      case currencies.usd:
        setSymbol('US$');
        setCurrencyDescription('$ ( USD )');
        setCurrencyFlag(countries.usa);
        break;
      case currencies.mxn:
        setSymbol('MXN');
        setCurrencyDescription('MXN ( Pesos mexicanos )');
        setCurrencyFlag(countries.mexico);
        break;
    }
  }

  /**
   * Event handler for closeModalAfterCertificateChoosen Event
   */
  const onCloseModalAfterCertificateChoosen = (_chosen) => {
    setCertificateDialogIsVisible(false);
    setChosenCertificates(_chosen);
  }

  /**
   * Event handler for change event in Installmets checkbox
   */
  const calculatePrices = (withInstallments) => {

    let prices = calculateCoursePrices(
        props.course, withInstallments, chosenCertificates
    );

    let certificatesPriceSum = sumCertificatesPrices(
        props.course, chosenCertificates
    )

    // update component state
    setCoursePrice(prices.coursePrice);
    setTotalPrice(prices.totalPrice);
    setCertificatesPrice(certificatesPriceSum);
  }

    // ---------------------------------------
    // Return component

    return (
        <div className="items-component ps-3 pe-3 p-lg-5"
          style={{background: params.company === companies.pragmma ? 'white' : '#f9f9f9'}}>
          <div className="item">
            <div className="item-description">
              <div className="type">{formatCourseType(props.course.type)}</div>
              <div className="name">{props.course.name}</div>
              <figure>
                <img src={`imgs/curso_${params.company}.png`} alt=""/>
              </figure>
            </div>

            {/* --------------------------------------
          Total purchase price
        */}
            <div className="total-wrapper">
              {/* Currency selector */}

              <div className="currency-selector-wrapper">
                {/* Currency selector button */}

                <div className="currency-selector">
                  <img
                      className="flag"
                      src={`imgs/lang/${currencyFlag}.svg`}
                      alt=""
                  />
                  <div className="description">{currencyDescription}</div>

                  {/* Currency selector menu */}

                  <ul className="menu">
                    <li onClick={() => convertCurrency(currencies.pen)}>
                      <img className="flag" src="imgs/lang/peru.svg" alt=""/>
                      S/ ( PEN )
                    </li>
                    { params.company === companies.enpp ||
                      params.company ===   companies.pragmma
                      ? <li onClick={() => convertCurrency(currencies.usd)}>
                        <img className="flag" src="imgs/lang/usa.svg" alt=""/>
                        $ ( USD )
                      </li>
                      : null
                    }

                  </ul>
                </div>
              </div>

              {/* Price details */}

              {params.company === companies.ipappg && !params.installmentNumber ? (
                  <div className="price-details">
                    <div
                        className="d-flex justify-content-between align-items-center mb-2">
                <span className="label">
                  {installmentsIsActive ? t("installment") : t("programCost")}
                </span>
                      <div className="subtotal-installments-wrapper">
                  <span className="subtotal">
                    {symbol} {coursePrice.toFixed(2)}
                  </span>{" "}
                        <br/>
                        {installmentsIsActive ? (
                            <span className="installments-description">
                      {t("inInstallments").replace(
                          "{0}",
                          props.course.installmentsCount
                      )}
                    </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center">
                      <span className="label">{t("certificateCost")}</span>
                      <span className="subtotal">
                  {symbol} {certificatesPrice.toFixed(2)}
                </span>
                    </div>
                  </div>
              ) : null}

              {params.company === companies.enpp || params.company === companies.pragmma ? (
                  <div className="price-details">
                    <div
                        className="d-flex justify-content-between align-items-center mb-2">
                      <span className="label">{t("programCost")}</span>
                      <div className="subtotal-installments-wrapper">
                  <span className="subtotal">
                    {symbol} {coursePrice.toFixed(2)}
                  </span>{" "}
                        <br/>
                        {installmentsIsActive ? (
                            <span className="installments-description">
                      {t("inInstallments").replace(
                          "{0}",
                          props.course.installmentsCount
                      )}
                    </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center">
                      {
                        params.company === companies.enpp
                            ? <span className="label">{t("digitalCertificateEnpp")}</span>
                            : null
                      }
                      {
                        params.company === companies.pragmma
                            ? <span className="label">{t("digitalCertificatePragmma")}</span>
                            : null
                      }
                      <span className="subtotal" style={{fontSize: "0.9rem"}}>
                  {t("free")}
                </span>
                    </div>
                  </div>
              ) : null}

              {/* Total price */}

              <div
                  className="d-flex justify-content-between align-items-center mt-3">
                {params.installmentNumber ? (
                    <span className="label">
                {getInstallmentNumber(params.installmentNumber, language)}{" "}
                      {t("installmentToPay")}
              </span>
                ) : (
                    <span className="label">{t("totalCost")}</span>
                )}

                <TotalPrice
                    symbol={symbol}
                    price={totalPrice}
                    installmentsIsActive={installmentsIsActive}
                    installmentsCount={props.course.installmentsCount}
                />
              </div>

              {/* Pay in installments selector */}

              {/* {params.company === companies.ipappg &&
                !params.installmentNumber &&
                props.isEditable &&
                props.course.installmentsCount > 1 ? (
                  <form className="default-form pay-installments">
                    <input
                      type="checkbox"
                      className="form-check-input installments-checkbox red"
                      onChange={(e) => {
                        setInstallmentsIsActive(e.target.checked);
                        calculatePrices(e.target.checked);
                      }}
                      value={installmentsIsActive}
                    />
                    <span className="label">{t("payInInstallments")}</span>
                  </form>
                ) : null} */}

              {/* Certificate chooser */}
              {/*
              { params.company === companies.ipappg &&
                !params.installmentNumber &&
                props.isEditable &&
                props.course?.certificates?.length > 0 ? (
                  <div className="pt-3">
                    <span
                        onClick={() => setCertificateDialogIsVisible(true)}
                        className="choose-certificate-button"
                    >
                      {t("chooseCertificate")}
                    </span>
                  </div>
              ) : null }*/}
            </div>
          </div>

          {/* ---------------------------------------
              Components for notifications, dialogs, popups, etc
          */}

          {/* Modal dialog to edit enrollment status */}
          {certificateDialogIsVisible ? (
              <ModalDialog
                  close={() => setCertificateDialogIsVisible(false)}
                  content={
                    <CertificateSelector
                        symbol={symbol}
                        certificates={props.course?.certificates}
                        chosenCertificates={chosenCertificates}
                        closeModal={() => setCertificateDialogIsVisible(false)}
                        closeModalAfterCertificateChoosen={
                          onCloseModalAfterCertificateChoosen
                        }
                    />
                  }
              />
          ) : null}
        </div>
    );
  }


export default Items;
