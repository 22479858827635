import * as XLSX from "xlsx";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import {
  fetchAdditionalPayments,
  fetchEnrollments, fetchGenericReport
} from "../../Enrollments/helper";
import SelectMenu from "../../../core/SelectMenu/SelectMenu";
import RoundButton from "../../../core/RoundButton/RoundButton";
import Spinner from "../../../core/Spinner/Spinner";
import {
  getDepartamentName,
  getDistrictName,
  getProvinceName,
} from "../../../checkout/StudentFormAndBankAccounts/StudentInformationForm/helpers";
import "./FileExporter.scss";
import {formatTimestampToDateString, getBankName} from "../../helpers/helpers";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FileExporter = (props) => {

  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [selectedMonths, setSelectedMonths] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [activeReportType, setActiveReportType] = useState('default');

  // Lifecycle hooks
  // ---------------------------------------

  // hook to be executed when component has been MOUNTED
  useEffect(() => {
    generateMonthsOptions();
  }, []);

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    generateMonthsOptions();
  }, [selectedMonths]);

  // ---------------------------------------
  // Functions

  /**
   * Iterate courses collection to find gift course,
   * and return its name
   * @param courseCode
   * @returns {string}
   */
  const getGiftCourseName = (courseCode) => {
    let _giftCourseName;
    props.courses.forEach((c) => {
      if (+c.code === courseCode) _giftCourseName = c.name;
    });

    return _giftCourseName;
  };

  /**
   * Fetch enrollments from server
   * @returns {Promise<void>}
   */
  const fetchDataAndDownloadReports = async () => {

    if (activeReportType === 'default') {

      await fetchAndDownloadEnrollments();
      await fetchAndDownloadAdditionalPayments();

    } else if (activeReportType === 'customers-concar' || 
               activeReportType === 'reg-ventas-concar') {

      await fetchGenericReport(
        params.company, 'students', activeReportType, 0, 'xlsx', selectedMonths
      );
    }
  }

  /**
   * Fetch enrollments' additional payments,
   * generate Excel file and download file
   * @returns {Promise<void>}
   */
  const fetchAndDownloadAdditionalPayments = async () => {
    // update component state
    setIsLoading(true);

    // fetch enrollments' additional payments from server

    let _additionalPayments = await fetchAdditionalPayments(
        props.companyName,  selectedMonths
    );

    // ---------------------------------------
    //  generate data in xlsx format

    let rows = [
      [
        t("Descripción"),
        t("Moneda"),
        t("Monto"),
        t("Banco"),
        t("Fecha de operación"),
        t("Nro de operación"),
        t('Nombre'),
        t('Apellido')
      ],
    ];
    _additionalPayments.forEach(item => {

      rows.push([
        item.description,
        item.currency_code.toUpperCase(),
        item.amount,
        getBankName(item.bank),
        formatTimestampToDateString(item.operation_datetime),
        item.operation_number,
        item.first_name,
        item.last_name
      ]);
    });

    // generate worksheet
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // generate workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // save to file
    let fileName = 'Otros pagos';
    XLSX.writeFile(wb, fileName + ".xlsx");

    // update component state
    setIsLoading(false);
  }

  /**
   * Fetch enrollments, generate Excel file and download file
   * @returns {Promise<void>}
   */
  const fetchAndDownloadEnrollments = async () => {

    // update component state
    setIsLoading(true);

    // fetch enrollments from server
    let _enrollments = await fetchEnrollments(
        props.companyName,
        0,
        "",
        "",
        "",
        selectedMonths
    );

    // ---------------------------------------
    //  generate data in xlsx format

    // generate a new line in csv for contact
    let rows = [
      [
        t("paymentMethod"),
        t("bank"),
        t("operationNumber"),
        t("amount"),
        t("paymentDate"),
        t("courseDiploma"),
        t('mode'),
        t("campaignNumber"),

        t("student"),
        "DNI",
        t("dateOfBirth"),
        t("email"),
        t("mobile"),

        t("departament"),
        t("province"),
        t("district"),
        t("address"),
        t("giftCourse"),
        t("howDidYouHearAboutUs"),

        t("payed"),
      ],
    ];
    _enrollments.items.forEach((item) => {
      rows.push([
        item.payment_method,
        item.bank,
        item.operation_number,
        item.amount,
        item.operation_datetime
            ? formatTimestampToDateString(item.operation_datetime)
            : formatTimestampToDateString(item.created_at),
        item.course_name,
        item.course_mode 
         ? item.course_mode.charAt(0).toUpperCase() + item.course_mode.slice(1)
         : '-'
        ,
        item.campaign_name,
        item.student,
        item.dni,
        formatTimestampToDateString(item.birthdate),
        item.email,
        item.mobile,

        getDepartamentName(item.department),
        getProvinceName(item.province),
        getDistrictName(item.district),
        item.address,
        getGiftCourseName(item.gift_course),
        item.how_did_you_hear_about_us,

        item.payed ? "Si" : "No",
      ]);
    });

    // generate worksheet
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // generate workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // save to file
    let fileName = 'Matriculas';
    XLSX.writeFile(wb, fileName + ".xlsx");

    // update component state
    setIsLoading(false);
  }

  /**
   * Generate a list of the last 12 months
   */
  const generateMonthsOptions = () => {
    let today = new Date();
    let currentYear = today.getFullYear();
    let previousYear = currentYear - 1;
    let currentMonth = today.getMonth() + 1; // months are zero based
    let monthsList = [];

    // generate months list
    for (let i = currentMonth; i > 0; i--) {
      monthsList.push({
        // when month number is less than 10, append a zero
        value: i < 10 ? `${currentYear}-0${i}` : `${currentYear}-${i}`,
        text: getMonthName(i) + " " + currentYear,
      });
    }

    // when month is less than 12, complete month from the previous year
    if (currentMonth < 12) {
      for (let i = 12; i > currentMonth; i--) {
        monthsList.push({
          // when month number is less than 10, append a zero
          value: i < 10 ? `${previousYear}-0${i}` : `${previousYear}-${i}`,
          text: getMonthName(i) + " " + previousYear,
        });
      }
    }

    // update component state
    setMonthsOptions(monthsList);
  };

  /**
   * Get localized month name
   * @param monthNumber
   * @returns {string|*}
   */
  const getMonthName = (monthNumber) => {
    let months = [
      '',
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december'),
    ];

    return months[monthNumber];
  };

  // ---------------------------------------
  // return component

  return (
      <div className="row">

        {/* Loading spinner */}

        {isLoading ? <Spinner/> : null}

        <div className="col-12 mb-1">

          {/* Report type selector */}

          <span className="control-label">
            {t("type")}
          </span>
          <SelectMenu
              description={t("selectAnOption")}
              optionSelectedCallback={(value) => setActiveReportType(value)}
              multiselection={false}
              options={[
                {value: 'default', text: 'Default'},
                {value: 'customers-concar', text: 'Anexos Concar'},
                {value: 'reg-ventas-concar', text: 'Reg Ventas Concar'},
              ]}
          />
        </div>

        <div className="col-12 mb-1">

          {/* Month selector */}

          <span className="control-label">{t("reportMonth")}</span>
          <SelectMenu
              description={t("selectAnOption")}
              optionSelectedCallback={(values) =>
                  setSelectedMonths(values.join(","))
              }
              multiselection={true}
              options={monthsOptions}
          />
        </div>

        <div className="col-12 mb-1 mt-3">
          
          {/* File format selector */}

          <span className="control-label">{t("fileFormat")}</span>
          <SelectMenu
              description={t("selectAnOption")}
              selectedItem={{value: "xlsx", text: 'Hoja de cálculo Excel'}}
              optionSelectedCallback={(v) => {
              }}
              options={[{value: "xlsx", text: 'Hoja de cálculo Excel'}]}
          />
        </div>
        <div className="col-12 mt-5">

          {/* Download button */}

          <RoundButton
              onClick={fetchDataAndDownloadReports}
              isDisabled={!selectedMonths || !activeReportType}
              width={"100%"}
              height={"3rem"}
              outline={false}
              content={t("download")}
          />
        </div>
      </div>
  );
};

export default FileExporter;
