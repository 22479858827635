import {uploadTypes} from "../store/enums";

export const getEndpoint = (name) => {
  
  // remove trailing slash (/)
  let base = process.env.REACT_APP_API_BASE_URL.replace(/\/+$/, "");

  // generate API endpoint
  let endpoint;
  switch (name) {
    case 'payments':
      endpoint = base + '/enrollments/payments/:enrollmentId';
      break;
    case "course":
      endpoint = base + "/courses/course/:company/:courseCode/:currencyCode";
      break;
    case "crud":
      endpoint =  `${base}/collections/:collectionName/:id`;
      break;
    case "course-installment":
      endpoint =
        base +
        "/courses/course/:company/:courseCode/:currencyCode/:studentId/:installmentNumber";
      break;
    case "course-discount":
      endpoint = base + "/courses/course/discount";
      break;
    case "courses":
      endpoint = base + "/courses/list/:company";
      break;
    case "courses-cache":
      endpoint = base + "/configuration/courses-cache";
      break;
    case "bank-transfer-enrollment":
      endpoint = base + "/enrollments/bank-transfers";
      break;
    case "bank-transfer-certificates":
      endpoint = base + "/enrollments/certificates/bank-transfers";
      break;
    case "configuration":
      endpoint = base + "/configuration";
      break;
    case "configuration_company":
      endpoint = base + "/configuration/:company";
      break;
    case "students":
      endpoint = base + "/students";
      break;
    case "gatewayToken":
      endpoint = base + "/payment-gateway/token/:gatewayName";
      break;
    case "finishTransaction":
      endpoint = base + "/payment-gateway/transactions/:gatewayName";
      break;
    case "login":
      endpoint = base + "/auth/login";
      break;
    case 'invoicing':
      endpoint = `${base}/invoicing/:company/:invoiceType/:enrollmentId`
      break;
    case 'void-invoice':
      endpoint = `${base}/invoicing/:companyName/:invoiceId`
      break;
    case 'download-invoice':
      endpoint = `${base}/invoicing/:companyName/:invoiceId`
      break;
    case 'invoice-config':
      endpoint = `${base}/invoicing/config/:companyName`
      break;
    case "logout":
      endpoint = base + "/auth/logout";
      break;
    case "authenticated-user":
      endpoint = base + "/auth/user";
      break;
    case "dni":
      endpoint = base + "/identity-documents/dni/{0}";
      break;

    case 'generic-report':
      endpoint = base + "/reports/{categoryName}/{reportName}/{page}/{format}";
      break;
    case "enrollments-report":
      endpoint = base + "/reports/students/enrollments/{0}";
      break;
    case "pending-installments-report":
      endpoint = base + "/reports/students/pending-installments/{0}";
      break;
    case "additional-payments-report":
      endpoint = base + "/reports/certificates/additional-payments-report/{0}";
      break;

    case "bank-invoice-upload":
      endpoint = base + "/files/urls/invoices/upload/{0}";
      break;
    case uploadTypes.certificateBankInvoice:
      endpoint = base + "/files/urls/certificates/invoices/upload/{0}";
      break;
    case "bank-invoice-download":
      endpoint = base + "/files/urls/invoices/download/{0}";
      break;
    case "bank-invoice-certificate-download":
      endpoint = base + "/files/urls/invoices/certificates/download/{0}";
      break;
    case "notification-installment":
      endpoint =
        base +
        "/notifications/installments/reminder/:companyName/:studentId/:language";
      break;
    case "payment-log":
      endpoint = base + "/enrollments/payment-log/{0}";
      break;
    case "enrollment":
      endpoint = base + "/enrollments/enrollment/{0}";
      break;

    default:
      endpoint = "";
      break;
  }

  return endpoint;
};
